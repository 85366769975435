// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cognito_identity_pool_id": "eu-west-1:ed1d8d1a-ce53-410c-af47-bd3d31f7dcda",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_C3rDUYFRL",
    "aws_user_pools_web_client_id": "1gkbchm80j9md3cc1jroqkrab3",
    "oauth": {},
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://jxx5hvw9ec.execute-api.eu-west-1.amazonaws.com/mobile",
            "region": "eu-west-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://dqxlwjct7jbkdigy6467hqmpky.appsync-api.eu-west-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_user_files_s3_bucket": "avsb67a9ae098da465e8bf00853b2982d0e130349-mobile",
    "aws_user_files_s3_bucket_region": "eu-west-1"
};


export default awsmobile;
