import React, { Component } from "react";
import DatePicker from "react-datepicker";
import { API, graphqlOperation, Storage, Auth } from 'aws-amplify';

import { Spinner, Button, Modal, Form} from 'react-bootstrap';

import moment from 'moment';
import 'moment/locale/sv';
 
import "../App.css"
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from  "react-datepicker";
import sv from 'date-fns/locale/sv';
import { S3Image } from "aws-amplify-react";
registerLocale('sv', sv);
moment.locale('sv');

export default class News extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            results: [],
            nextToken: null,
            getMorePossible: false,
            modalData: null,
            modalShown: false,
            newImages: [],
            createModalVisible: false,
            // new news data
            publishedFrom: null,
            publishedTo: null,
            pinned: false,
            pinnedFrom: null,
            pinnedTo: null,
            title: undefined,
            body: undefined,
            images: null,
            pickedImages: null,
            imagesPicked: false,
        }
        this.handleBodyChange = this.handleBodyChange.bind(this);
        this.handleTitleChange = this.handleTitleChange.bind(this);
        this.handlePinnedChange = this.handlePinnedChange.bind(this);
        this.updateTitle = this.updateTitle.bind(this);
    }

    componentDidMount() {
        this.checkUserGroups()
    }

    async checkUserGroups(){
        let apiName = 'AdminQueries';
        let path = '/listGroupsForUser';
        let myInit = { 
            queryStringParameters: {
              "username": await Auth.currentUserInfo().then(res => {return res.username}),
            },
            headers: {
              'Content-Type' : 'application/json',
              Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
            }
        }
        const { NextToken, ...rest } =  await API.get(apiName, path, myInit);
        console.log(rest)
        if (rest.Groups[0].GroupName !== 'Administrators') {
            Auth.signOut();
        }
        else {
            this.getNews();
        }
      }

    getNews = () => {
        API.graphql(graphqlOperation(`query searchNews($limit: Int, $nextToken: String, $sort: SearchableNyhetSortInput) {
            searchNyhets(limit: $limit, nextToken: $nextToken, sort: $sort) {
              items {
                id
                createdAt
                updatedAt
                publishedFrom
                publishedTo
                pinned
                pinnedFrom
                pinnedTo
                author {
                  name
                }
                images {
                  key
                }
                title
                body
              }
              nextToken
            }
          }`, {limit: 2, nextToken: this.state.nextToken, sort: {field: "createdAt" }})).then(response => {
                if (response.data.searchNyhets.nextToken != null) {
                    var data1 = [...this.state.results, ...response.data.searchNyhets.items];
                    this.setState({results: data1, nextToken: response.data.searchNyhets.nextToken, getMorePossible: true, loading: false})
                }
                else {
                    var data2 = [...this.state.results, ...response.data.searchNyhets.items];
                    this.setState({results: data2, nextToken: null, getMorePossible: false, loading: false})
                }
          }).catch(err => console.log(err))
    }

    updateValue = (key, value) => {
        var oldData = this.state.modalData;
        oldData[key] = value;
        this.setState({modalData: oldData})
    }

    handleBodyChange = (event) => {
        this.updateValue('body', event.target.value)
    }

    handleTitleChange = (event) => {
        this.updateValue('title', event.target.value)
    }

    handlePinnedChange = (event) => {
        this.updateValue('pinned', event.target.checked)
    }

    removeImage = (i) => {
        var oldData = this.state.modalData;
        oldData.images.splice(i,i+1)
        this.setState({modalData: oldData})
    }

    onCancel = () => {
        this.setState({modalShown: false, createModalVisible: false})
        window.location.reload();
    }

    uploadImages = () => {
        return new Promise((resolve,reject) => {
            for (var i = 0; i < this.state.pickedImages.length; i++) {
                Storage.put(`${this.state.pickedImages[i].name}`,
                this.state.pickedImages[i],
                {
                    contentType: this.state.pickedImages[i].type
                })
                .then(result => {
                    if (this.state.images == null) {
                        this.setState({images: []})
                    }
                    var previousImagesState = this.state.images;
                    previousImagesState.push(result)
                    if (this.state.images.length === this.state.pickedImages.length) {
                        resolve(this.state.images);
                    }
                })
                .catch(err => {
                    console.log(err)
                });
            }
        })
        
    };

    EditModal = () => {
        if (this.state.modalShown) {
            return (
                <>
                <Modal dialogClassName="modal-90w" show={this.state.modalShown} onHide={() => this.onCancel()}>
                    <Modal.Header closeButton>
                    <Modal.Title>Redigera</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        
                        <div className="formRow">
                            <span>Publicerad från</span>
                            <DatePicker
                                className="form-control"
                                selected={new Date(this.state.modalData.publishedFrom)}
                                onChange={(date) => this.updateValue('publishedFrom', date)}
                                locale="sv"
                                dateFormat="yyyy-MM-dd"
                            />
                        </div>
                        <div className="formRow">
                            <span>Publicerad till</span>
                            <DatePicker
                                className="form-control"
                                selected={new Date(this.state.modalData.publishedTo)}
                                onChange={(date) => this.updateValue('publishedTo', date)}
                                locale="sv"
                                dateFormat="yyyy-MM-dd"
                            />
                        </div>
                        <div className="formRow">
                            <span>Klistrad</span>
                            <Form.Check checked={this.state.modalData.pinned} onChange={this.handlePinnedChange.bind(this)}/>
                        </div>
                    {
                        this.state.modalData.pinned &&
                        <div>
                            <div className="formRow">
                                <span>Klistrad från</span>
                                <DatePicker
                                    className="form-control"
                                    disabled={!this.state.modalData.pinned}
                                    selected={new Date(this.state.modalData.pinnedFrom)}
                                    onChange={(date) => this.updateValue('pinnedFrom', date)}
                                    locale="sv"
                                    dateFormat="yyyy-MM-dd"
                                />
                            </div>
                            <div className="formRow">
                                <span>Klistrad till</span>
                                <DatePicker
                                    className="form-control"
                                    readOnly={!this.state.modalData.pinned}
                                    selected={new Date(this.state.modalData.pinnedTo)}
                                    onChange={(date) => this.updateValue('pinnedTo', date)}
                                    locale="sv"
                                    dateFormat="yyyy-MM-dd"
                                />
                            </div> 
                        </div>
                    }
                        <div className="formText">
                            <span>Titel</span>
                            <Form.Control type="text" value={this.state.modalData.title} onChange={this.handleTitleChange.bind(this)}/>
                        </div>
                        <div className="formText">
                            <span>Brödtext</span>
                            <Form.Control as="textarea" rows="3" value={this.state.modalData.body} onChange={this.handleBodyChange.bind(this)}/>
                        </div> 
                        <div className="formImages">
                            <span>Bilder</span>
                            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                            {
                                this.state.modalData.images.map((image, i) => (
                                    <div key={i} style={{margin: 5, alignContent: 'center', justifyContent: 'center'}}>
                                        <S3Image theme={{ photoImg: { width: '100%', height: undefined, objectFit: 'cover', borderRadius: 10 }}} imgKey={image.key} />
                                        <Button className="btn btn-danger btn-block" onClick={() => this.removeImage(i)}>Ta bort</Button>
                                    </div>
                                ))
                            }
                            </div>
                        </div>    
                                
                    </Modal.Body>
                    <Modal.Footer>
                    <Button variant="secondary" onClick={() => this.onCancel()}>
                        Avbryt
                    </Button>
                    <Button variant="primary" onClick={() => this.saveNews(this.state.modalData)}>
                        Spara ändringar
                    </Button>
                    </Modal.Footer>
                </Modal>
                </>
            );
        }
        else {
            return(
                <>
                </>
            )
        }
    }

    updateTitle = (event) => {
        this.setState({title: event.target.value})
    }

    updateBody = (event) => {
        this.setState({body: event.target.value})
    }

    updateDates = (key, date) => {
        this.setState({[key]: new Date(date)})
    }

    updatePinned = (event) => {
        this.setState({pinned: event.target.checked})
    }

    pickImages = async (event) => {
        await this.setState({
            pickedImages: event.target.files
        });
        console.log(this.state.pickedImages)
    }

    createNewsModal = () => {
        if (this.state.createModalVisible) {
            return (
                <>
                <Modal dialogClassName="modal-90w" show={this.state.createModalVisible} onHide={() => this.onCancel()}>
                    <Modal.Header closeButton>
                    <Modal.Title>Redigera</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        
                        <div className="formRow">
                            <span>Publicerad från</span>
                            <DatePicker
                                className="form-control"
                                selected={this.state.publishedFrom}
                                onChange={(date) => this.updateDates('publishedFrom', date)}
                                locale="sv"
                                dateFormat="yyyy-MM-dd"
                            />
                        </div>
                        <div className="formRow">
                            <span>Publicerad till</span>
                            <DatePicker
                                className="form-control"
                                selected={this.state.publishedTo}
                                onChange={(date) => this.updateDates('publishedTo', date)}
                                locale="sv"
                                dateFormat="yyyy-MM-dd"
                            />
                        </div>
                        <div className="formRow">
                            <span>Klistrad</span>
                            <Form.Check checked={this.state.pinned} onChange={e => this.updatePinned(e)}/>
                        </div>
                    {
                        this.state.pinned &&
                        <div>
                            <div className="formRow">
                                <span>Klistrad från</span>
                                <DatePicker
                                    className="form-control"
                                    disabled={!this.state.pinned}
                                    selected={this.state.pinnedFrom}
                                    onChange={(date) => this.updateDates('pinnedFrom', date)}
                                    locale="sv"
                                    dateFormat="yyyy-MM-dd"
                                />
                            </div>
                            <div className="formRow">
                                <span>Klistrad till</span>
                                <DatePicker
                                    className="form-control"
                                    readOnly={!this.state.pinned}
                                    selected={this.state.pinnedTo}
                                    onChange={(date) => this.updateDates('pinnedTo', date)}
                                    locale="sv"
                                    dateFormat="yyyy-MM-dd"
                                />
                            </div> 
                        </div>
                    }
                        <div className="formText">
                            <span>Titel</span>
                            <Form.Control type="text" value={this.state.title} onChange={this.updateTitle.bind(this)}/>
                        </div>
                        <div className="formText">
                            <span>Brödtext</span>
                            <Form.Control as="textarea" rows="3" value={this.state.body} onChange={this.updateBody.bind(this)}/>
                        </div> 
                        <div className="formImages">
                            <span>Bilder</span>
                            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                                <input type='file' accept="image/png, image/jpeg" multiple ref={ref=> this.fileInput = ref} onChange={e => this.pickImages(e)}/>
                            {
                                this.state.pickedImages !== null &&
                               <Button variant="secondary" onClick={() => {
                                   this.fileInput.value = ""
                                   this.setState({pickedImages: null})
                                } }>Rensa</Button>
                            }
                            </div>
                        </div>    
                                
                    </Modal.Body>
                    <Modal.Footer>
                    <Button variant="secondary" onClick={() => this.onCancel()}>
                        Avbryt
                    </Button>
                    <Button variant="primary" onClick={() => this.createNews()}>
                        Skapa nyhet
                    </Button>
                    </Modal.Footer>
                </Modal>
                </>
            )
        }
        else {
            return (
                <>
                </>
            )
        }
    }

    createNews = async () => {
        if (this.state.pickedImages.length > 0) {
            this.uploadImages().then(async () => {
                let input = {
                    publishedFrom: this.state.publishedFrom,
                    publishedTo: this.state.publishedTo,
                    pinned: this.state.pinned,
                    pinnedFrom: this.state.pinnedFrom,
                    pinnedTo: this.state.pinnedTo,
                    title: this.state.title,
                    body: this.state.body,
                    images: this.state.images,
                    createdAt: new Date(),
                    nyhetAuthorId: await Auth.currentUserInfo().then(res => {return res.username })
                }
                console.log(input);
                API.graphql(graphqlOperation(`mutation createNews($input: CreateNyhetInput!) {
                    createNyhet(input: $input) {
                        id
                    }
                }`, {input: input})).then(res => {
                    console.log(res)
                }).catch(err => console.log(err))
            });
        }
        else {
            let input = {
                publishedFrom: this.state.publishedFrom,
                publishedTo: this.state.publishedTo,
                pinned: this.state.pinned,
                pinnedFrom: this.state.pinnedFrom,
                pinnedTo: this.state.pinnedTo,
                title: this.state.title,
                body: this.state.body,
                createdAt: new Date(),
                nyhetAuthorId: await Auth.currentUserInfo().then(res => {return res.username })
            }
            console.log(input);
            API.graphql(graphqlOperation(`mutation createNews($input: CreateNyhetInput!) {
                createNyhet(input: $input) {
                    id
                }
            }`, {input: input})).then(res => {
                console.log(res)
            }).catch(err => console.log(err))
        }
    }

    saveNews = (data) => {
        delete data.author;
        API.graphql(graphqlOperation(`mutation updateNews($input: UpdateNyhetInput!) {
            updateNyhet(input: $input) {
              id
            }
          }`, {input: data})).then(res => {
            console.log('Updating..')
            window.location.reload();
        }).catch(err => {
            console.log(err);
        })
    }

    deleteNews = (id) => {
        const confirmDelete = window.confirm(`Vill du verkligen ta bort nyheten?`);
        if (confirmDelete) {
            API.graphql(graphqlOperation(`mutation deleteNews($input: DeleteNyhetInput!) {
                deleteNyhet(input: $input) {
                  id
                }
              }`, {input: {id: id}})).then(res => {
                console.log('Delete..')
                this.getNews();
            }).catch(err => {
                console.log(err);
            })
        }
    }

    render() {

        if (this.state.loading) {
            return(
                <div>
                    <Spinner animation="border" role="status">
                        <span className="sr-only">Loading...</span>
                    </Spinner>
                </div>
            )
        }
        
        return (
            this.state.results.length < 1 && !this.state.loading ? 
            <div>
                <p>Inga resultat</p>
            </div> 
            :
            <div>
                <this.EditModal/>
                <this.createNewsModal/>
                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: 10}}>
                    <h3>Nyheter</h3>
                    <Button onClick={() => this.setState({createModalVisible: true})} variant="success">Skapa nyhet</Button>
                </div>
        {
            this.state.results.map((data, i) => (
                <div key={i} style={{ marginBottom: 25, backgroundColor: '#f5f6f6', borderRadius: 10}}>
                    {
                        data.images.length > 0 ?
                        <div style={{height: 'auto', width: '100%', marginBottom: 5}}>
                            <S3Image theme={{ photoImg: { width: '100%', height: 200, objectFit: 'cover', borderTopLeftRadius: 10, borderTopRightRadius: 10 }}} imgKey={data.images.length > 0 ? data.images[0].key : null} />
                        </div>
                        : null
                    }
                    <div style={{padding: 10, flex: 1}}>
                        <h5>{data.title}</h5>
                        <div>
                            <span>Publicerades {moment(data.createdAt).calendar()}</span>
                        </div>
                        <div>
                            <span>Publicerades av {data.author.name}</span>
                        </div>
                        <div>
                            <span>Visas: </span><span style={(new Date() >= new Date(data.publishedFrom) && (new Date() <= new Date(data.publishedTo))) ? {color: 'green'} : {color: 'red'}}>{(new Date() >= new Date(data.publishedFrom) && (new Date() <= new Date(data.publishedTo))) ? 'Ja' : 'Nej'}</span>
                        </div>
                        <div>
                            <span>Publicerad från {moment(data.publishedFrom).calendar()}</span>
                        </div>
                        <div>
                            <span>Publicerad till {moment(data.publishedTo).calendar()}</span>
                        </div>
                        <div>
                            <span>Klistrad: </span><span style={data.pinned ? {color: 'green'} : {color: 'red'}}>{data.pinned ? 'Ja' : 'Nej'}</span>
                        </div>
                        
                    </div>
                    <div style={{padding: 10, flex: 1}}>
                        <button onClick={() => this.setState({modalShown: true, modalData: data})} className="btn btn-warning btn-block">Redigera</button>
                        <button onClick={() => this.deleteNews(data.id)} className="btn btn-danger btn-block">Ta bort</button>
                    </div>
                </div>
            ))
        }
            <button onClick={() => this.getNews()} disabled={this.state.getMorePossible === true ? false : true} className="btn btn-success btn-block">Ladda fler</button>
            </div>
        )
        
    }
}