import React, { Component } from "react";
import DatePicker from "react-datepicker";
import { API, graphqlOperation } from 'aws-amplify';

import { Dropdown, Spinner } from 'react-bootstrap';

import moment from 'moment';
import 'moment/locale/sv';
 
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from  "react-datepicker";
import sv from 'date-fns/locale/sv';
registerLocale('sv', sv);
moment.locale('sv');



const reasons = {
    VACATION: {
        label: "Semester"
    },
    VAB: {
        label: "Vård av barn"
    },
    OFFDUTY: {
        label: "Tjänstledigt"
    },
    SICK: {
        label: "Sjuk"
    },
    ALL: {
        label: 'Alla'
    },
    OVERTIME: {
        label: 'Övertid'
    },
    VAA: {
        label: 'Vård av anhörig'
    },
    PARENTAL: {
        label: 'Föräldraledig'
    }
}

const statuses = {
    ALL: {
        label: "Alla"
    },
    APPROVED: {
        label: "Godkänd"
    },
    DENIED: {
        label: "Nekad"
    },
    NEW: {
        label: "Ej behandlad"
    }
}

export default class Login extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            startDate: new Date(),
            endDate: new Date(),
            selectedReason: 'ALL',
            selectedStatus: 'APPROVED',
            results: null,
        }
    
        // This binding is necessary to make `this` work in the callback
        this.getReport = this.getReport.bind(this);
    }

    getReport = (e) => {
        this.setState({loading: true})
        e.preventDefault();
        API.graphql(graphqlOperation(`query listAbsences($filter: ModelAbsenceFilterInput!) {
            listAbsences(filter: $filter, limit: 999) {
              items {
                id
                fromDate
                toDate
                reason
                status
                employee {
                  name
                }
              }
            }
          }`, {
              filter: {
                  fromDate: {
                      ge: `${moment(this.state.startDate).format("YYYY-MM-DD")}T00:00:00.000Z`
                    },
                    and: {
                        toDate: {
                            le: `${moment(this.state.endDate).format("YYYY-MM-DD")}T23:59:59.000Z`
                        }
                    },
                    status: this.state.selectedStatus === 'ALL' ?
                    {
                        ne: 'ALL'
                    }
                    :
                    {
                        eq: this.state.selectedStatus
                    },
                    reason: this.state.selectedReason === 'ALL' ?
                    {
                        ne: 'ALL'
                    }
                    :
                    {
                        eq: this.state.selectedReason
                    }
                }
            })).then(data => {
            console.log(data)
            var result = data.data.listAbsences.items.reduce(function (r, a) {
                r[a.employee.name] = r[a.employee.name] || [];
                r[a.employee.name].push(a);
                return r;
            }, Object.create(null));

            this.setState({results: result, loading: false})
        }).catch(error => {
            this.setState({loading: false})
        })
    }

    workingHoursBetweenDates(startDate, endDate) {  

        startDate = new Date(startDate);
        endDate = new Date(endDate);

        // Store minutes worked
        var minutesWorked = 0;
    
        // Validate input
        if (endDate < startDate) { return 0; }
    
        // Loop from your Start to End dates (by hour)
        var current = startDate;
    
        // Define work range
        var workHoursStart = 8;
        var lunchHoursStart = 12;
        var lunchHoursEnd = 13;
        var workHoursEnd = 17;
        // var includeWeekends = false;
    
        // Loop while currentDate is less than end Date (by minutes)
        while(current <= endDate){          
            // Is the current time within a work day (and if it 
            // occurs on a weekend or not)          
            if((current.getHours() >= workHoursStart) && (current.getHours() < workHoursEnd) && (current.getDay() !== 0) && (current.getDay() !== 6)){
                if ((current.getHours() >= lunchHoursStart) && (current.getHours() < lunchHoursEnd)) {
                    minutesWorked = minutesWorked - 1;
                }
                  minutesWorked++;
            }
    
            // Increment current time
            current.setTime(current.getTime() + 1000 * 60);
        }
    
        // Return the number of hours
        return minutesWorked / 60;
    }

    workingHoursBetweenDatesOvertime(startDate, endDate) {  

        startDate = new Date(startDate);
        endDate = new Date(endDate);

        // Store minutes worked
        var minutesWorked = 0;
    
        // Validate input
        if (endDate < startDate) { return 0; }
    
        // Loop from your Start to End dates (by hour)
        var current = startDate;
    
        // Define work range
        var workHoursStart = 8;
        var lunchHoursStart = 12;
        var lunchHoursEnd = 13;
        var workHoursEnd = 17;
        // var includeWeekends = false;
    
        // Loop while currentDate is less than end Date (by minutes)
        while(current <= endDate){          
            // Is the current time within a work day (and if it 
            // occurs on a weekend or not)          

                  minutesWorked++;
    
            // Increment current time
            current.setTime(current.getTime() + 1000 * 60);
        }
    
        // Return the number of hours
        return minutesWorked / 60;
    }

    round(value, precision) {
        var multiplier = Math.pow(10, precision || 0);
        return Math.round(value * multiplier) / multiplier;
    }

    render() {
        if (this.state.results == null) {
            return (
                <div>
                    <div style={{width: 300, margin: 'auto'}}>
                        <h3>Ny rapport</h3>
                        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                            <div style={{width: '45%'}}>
                                <span style={{marginRight: 10}}>Från</span>
                                <DatePicker
                                    className="form-control"
                                    selected={this.state.startDate}
                                    onChange={(date) => this.setState({startDate: new Date(date)})}
                                    locale="sv"
                                    dateFormat="yyyy-MM-dd"
                                />
                            </div>
                            <div style={{width: '45%'}}>
                                <span style={{marginRight: 10}}>Till</span>
                                <DatePicker
                                    className="form-control"
                                    selected={this.state.endDate}
                                    onChange={(date) => this.setState({endDate: date})}
                                    locale="sv"
                                    dateFormat="yyyy-MM-dd"
                                />
                            </div>
                        </div>
                        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: 10}}>
                            <div>
                                <span>Anledning</span>
                                <Dropdown>
                                    <Dropdown.Toggle onSelect={(value) => console.log(value)} variant="success" id="dropdown-basic">
                                        {reasons[this.state.selectedReason].label}
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu onSelect={(value) => console.log(value)}>
                                        <Dropdown.Item onSelect={(value) => this.setState({selectedReason: value})} eventKey="ALL">Alla</Dropdown.Item>
                                        <Dropdown.Item onSelect={(value) => this.setState({selectedReason: value})} eventKey="SICK">Sjuk</Dropdown.Item>
                                        <Dropdown.Item onSelect={(value) => this.setState({selectedReason: value})} eventKey="VAB">Vård av barn</Dropdown.Item>
                                        <Dropdown.Item onSelect={(value) => this.setState({selectedReason: value})} eventKey="VACATION">Semester</Dropdown.Item>
                                        <Dropdown.Item onSelect={(value) => this.setState({selectedReason: value})} eventKey="OFFDUTY">Tjänstledig</Dropdown.Item>
                                        <Dropdown.Item onSelect={(value) => this.setState({selectedReason: value})} eventKey="OVERTIME">Övertid</Dropdown.Item>
                                        <Dropdown.Item onSelect={(value) => this.setState({selectedReason: value})} eventKey="VAA">Vård av anhörig</Dropdown.Item>
                                        <Dropdown.Item onSelect={(value) => this.setState({selectedReason: value})} eventKey="PARENTAL">Föräldraledig</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                            <div>
                                <span>Status</span>
                                <Dropdown>
                                    <Dropdown.Toggle onSelect={(value) => console.log(value)} variant="success" id="dropdown-basic">
                                        {statuses[this.state.selectedStatus].label}
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu onSelect={(value) => console.log(value)}>
                                        <Dropdown.Item onSelect={(value) => this.setState({selectedStatus: value})} eventKey="ALL">Alla</Dropdown.Item>
                                        <Dropdown.Item onSelect={(value) => this.setState({selectedStatus: value})} eventKey="APPROVED">Godkända</Dropdown.Item>
                                        <Dropdown.Item onSelect={(value) => this.setState({selectedStatus: value})} eventKey="DENIED">Nekade</Dropdown.Item>
                                        <Dropdown.Item onSelect={(value) => this.setState({selectedStatus: value})} eventKey="NEW">Ej behandlade</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </div>
                        <button style={{marginTop: 10}} className="btn btn-primary btn-md" onClick={(e) => this.getReport(e)}>Skapa rapport</button>
                    </div>
                </div>
            );
        }

        if (this.state.loading) {
            return(
                <div>
                    <Spinner animation="border" role="status">
                        <span className="sr-only">Loading...</span>
                    </Spinner>
                </div>
            )
        }
        console.log(this.state.results)
        return (

            Object.keys(this.state.results).length === 0 ? 
            <div className="container">
                <p>Inga resultat</p>
                <button onClick={(e) => this.setState({results: null})} className="btn btn-warning btn-block">Ny sökning</button>
            </div> 
            :
            <div className="container">
            {
            Object.keys(this.state.results).map((data, i) => (
                <div key={data}>
                    <ul className="list-group">
                        <li key={data} className="list-group-item active">{data}</li>
                        <li key={i} className="list-group-item">
                            <div className="row">
                                <div className="col columnHeader">Från</div>
                                <div className="col columnHeader">Till</div>
                                <div className="col columnHeader">Tid</div>
                                <div className="col columnHeader">Anledning</div>
                                <div className="col columnHeader">Status</div>
                            </div>
                        </li>
                {
                    this.state.results[data].map((newData, i) => (
                        <li key={i} className="list-group-item">
                            <div className="row">
                                <div className="col">{moment(newData.fromDate).format("YYYY-MM-DD HH:mm")}</div>
                                <div className="col">{moment(newData.toDate).format("YYYY-MM-DD HH:mm")}</div>
                                <div className="col">{reasons[newData.reason].label !== 'Övertid' ? this.workingHoursBetweenDates(newData.fromDate, newData.toDate) <= 8 ? this.round(this.workingHoursBetweenDates(newData.fromDate, newData.toDate), 1) : `${this.round(this.workingHoursBetweenDates(newData.fromDate, newData.toDate)/8, 1)} arbetsdagar (${this.round(this.workingHoursBetweenDates(newData.fromDate, newData.toDate), 1)} arbetstimmar)` : this.workingHoursBetweenDatesOvertime(newData.fromDate, newData.toDate) <= 8 ? this.round(this.workingHoursBetweenDatesOvertime(newData.fromDate, newData.toDate), 1) : `${this.round(this.workingHoursBetweenDatesOvertime(newData.fromDate, newData.toDate)/8, 1)} arbetsdagar (${this.round(this.workingHoursBetweenDatesOvertime(newData.fromDate, newData.toDate), 1)} arbetstimmar`} {this.round(this.workingHoursBetweenDates(newData.fromDate, newData.toDate)/8, 1) < 2 ? 'arbetstimmar' : null}</div>
                                <div className="col">{reasons[newData.reason].label}</div>
                                <div className="col">{statuses[newData.status].label}</div>
                            </div>
                        </li>
                    ))
                }
                    </ul>
                </div>
            ))
            }
                <button onClick={(e) => this.setState({results: null})} className="btn btn-warning btn-block">Ny sökning</button>
            </div>
        )
        
    }
}