import React, { Component } from "react";

import moment from 'moment';
import 'moment/locale/sv';

import { API, graphqlOperation} from 'aws-amplify';

import ReactMapGL, { Marker } from 'react-map-gl';

import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from  "react-datepicker";

import { Bar } from 'react-chartjs-2';

import sv from 'date-fns/locale/sv';
registerLocale('sv', sv);
moment.locale('sv');

const ICON = `M20.2,15.7L20.2,15.7c1.1-1.6,1.8-3.6,1.8-5.7c0-5.6-4.5-10-10-10S2,4.5,2,10c0,2,0.6,3.9,1.6,5.4c0,0.1,0.1,0.2,0.2,0.3
  c0,0,0.1,0.1,0.1,0.2c0.2,0.3,0.4,0.6,0.7,0.9c2.6,3.1,7.4,7.6,7.4,7.6s4.8-4.5,7.4-7.5c0.2-0.3,0.5-0.6,0.7-0.9
  C20.1,15.8,20.2,15.8,20.2,15.7z`;

const SIZE = 20;

export default class Home extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: null,
            loading: true,
            viewport: {
                latitude: 63.13362935888953,
                longitude: 16.863616707152232,
                zoom: 3.06746974774743
            },
            topList: null,
            chartLabels: [],
            chartDatasets: [],
            lineLabels: ['Januari', 'Februari', 'Mars', 'April', 'Maj', 'Juni', 'Juli', 'Augusti', 'September', 'Oktober', 'November', 'December'],
            lineCounts: [],
            madeToday: 0,
            madeYesterday: 0,
            allPagesdata: [],
        }
    }

    componentDidMount() {
        // this.getChecklists();
        this.getMoreChecklist(null);
    }

    getMoreChecklist(token) {

        API.graphql(graphqlOperation(`query listChecklists($nextToken: String) {
            listChecklists(nextToken: $nextToken, limit: 50) {
              items {
                id
                createdAt
                location {
                  lat
                  lon
                }
                installer {
                  name
                }
                checklistCompany {
                  id
                  name
                }
              }
              nextToken
            }
        }`, {nextToken: token})).then(res => {
            console.log(res)
            let lastData = this.state.allPagesdata;
            let currData = res.data.listChecklists.items;
            let allData = [...lastData, ...currData];
            console.log(allData.length)
            this.setState({allPagesdata: allData});

            console.log(res.data.listChecklists.items[0])

            if (res.data.listChecklists.nextToken !== null) {
                this.getMoreChecklist(res.data.listChecklists.nextToken)
            }
            else {
                let todayCount = 0;
                let yesterdayCount = 0;
                this.state.allPagesdata.forEach(item => {
                    let createdAt = new Date(item.createdAt);
                    let today = new Date();
                    let yesterday = new Date();
                    yesterday.setDate(yesterday.getDate()-1);
                    if (createdAt.toDateString() === today.toDateString()) {
                        todayCount++;
                    }
                    if (createdAt.toDateString() === yesterday.toDateString()) {
                        yesterdayCount++;
                    }
                })
    
                var data = this.state.allPagesdata;
                var toplist = data.reduce(function (acc, curr) {
                    if (typeof acc[curr.installer.name] === 'undefined') {
                        acc[curr.installer.name] = 1;
                      } else {
                        acc[curr.installer.name] += 1;
                      }
                    
                    return acc;
                }, {});
                var sortable = [];
                for (var installer in toplist) {
                    sortable.push({
                        name: installer,
                        value: toplist[installer]
    
                    })
                }
                sortable.sort(function(a, b) { return b.value - a.value; });
    
                var labels = sortable.map((item, i) => {
                    return item.name;
                })
    
                var dataNumbers = sortable.map((item, i) => {
                    return item.value;
                })
    
                this.setState({data: data, topList: sortable, loading: false, chartLabels: labels, chartDatasets: dataNumbers, madeToday: todayCount, madeYesterday: yesterdayCount})
            }
        })
    }

    getChecklists() {
        API.graphql(graphqlOperation(`query searchChecklists($limit: Int) {
            searchChecklists(limit: $limit) {
                items {
                    id
                    createdAt
                    location {
                        lat
                        lon
                    }
                    installer {
                        name
                    }
                    checklistCompany {
                        id
                    }
                }
            }
        }`, {limit: 9999})).then(res => {

            let todayCount = 0;
            let yesterdayCount = 0;
            res.data.searchChecklists.items.forEach(item => {
                let createdAt = new Date(item.createdAt);
                let today = new Date();
                let yesterday = new Date();
                yesterday.setDate(yesterday.getDate()-1);
                if (createdAt.toDateString() === today.toDateString()) {
                    todayCount++;
                }
                if (createdAt.toDateString() === yesterday.toDateString()) {
                    yesterdayCount++;
                }
            })

            var data = res.data.searchChecklists.items;
            var toplist = data.reduce(function (acc, curr) {
                if (typeof acc[curr.installer.name] === 'undefined') {
                    acc[curr.installer.name] = 1;
                  } else {
                    acc[curr.installer.name] += 1;
                  }
                
                return acc;
            }, {});
            var sortable = [];
            for (var installer in toplist) {
                sortable.push({
                    name: installer,
                    value: toplist[installer]

                })
            }
            sortable.sort(function(a, b) { return b.value - a.value; });

            var labels = sortable.map((item, i) => {
                return item.name;
            })

            var dataNumbers = sortable.map((item, i) => {
                return item.value;
            })

            this.setState({data: data, topList: sortable, loading: false, chartLabels: labels, chartDatasets: dataNumbers, madeToday: todayCount, madeYesterday: yesterdayCount})
        })
    }

    Markers = () => {
        if (!this.state.loading) {
            return (
                this.state.data.map((checklist, i) => (
                    <Marker key={checklist.id} longitude={parseFloat(checklist.location.lon)} latitude={parseFloat(checklist.location.lat)}>
                        <svg
                        height={SIZE}
                        viewBox="0 0 24 24"
                        style={{
                            cursor: 'pointer',
                            fill: checklist.checklistCompany.id === '889c114c-01f5-4c6b-a11d-43692c4962ee' ? 'orange' : 'blue',
                            stroke: 'none',
                            transform: `translate(${-SIZE / 2}px,${-SIZE}px)`
                        }}
                        //onClick={() => onClick(city)}
                        >
                            <path d={ICON} />
                        </svg>
                    </Marker>
                ))
            )
        }
        else {
            return (
                <>
                </>
            )
        }
    }

    Stats = () => {
        if (!this.state.loading) {
            return (
                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                    <div style={{backgroundColor: '#f6f6f6', padding: 20, display: 'flex', flexDirection: 'column', alignItems: 'center', flex: 2, marginRight: 10}}>
                        <span style={{fontSize: 14, marginBottom: 10}}>Antal checklistor</span>
                        <span style={{fontSize: 50}}>{this.state.data.length} st</span>
                    </div>
                    <div style={{backgroundColor: '#f6f6f6', padding: 20, display: 'flex', flexDirection: 'row', alignItems: 'center', flex: 2, justifyContent: 'space-evenly'}}>
                        <div style={{display: 'flex', justifyContent: 'center', alignContent: 'center', flexDirection:'column'}}>
                            <span style={{fontSize: 14, marginBottom: 5, textAlign: 'center'}}>Idag</span>
                            <div style={{display: 'flex', justifyContent: 'center', alignContent: 'center', flexDirection:'column'}}>
                                <span style={{fontSize: 50, textAlign: 'center'}}>{`${this.state.madeToday} st`}</span>
                            </div>
                        </div>
                        <div style={{display: 'flex', justifyContent: 'center', alignContent: 'center', flexDirection:'column'}}>
                            <span style={{fontSize: 14, marginBottom: 5, textAlign: 'center'}}>Igår</span>
                            <div style={{display: 'flex', justifyContent: 'center', alignContent: 'center', flexDirection:'column'}}>
                                <span style={{fontSize: 50, textAlign: 'center'}}>{`${this.state.madeYesterday} st`}</span>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        else {
            return (
                <>
                </>
            )
        }
    }

    render() {

        if (this.state.loading) {
            return (
                <p>Laddar..</p>
            )
        }
        else {
            return (
                <div>
                    <div>
                        <h3>Statistik</h3>
                        <this.Stats/>
                    </div>
                    <div>
                        <Bar
                            data={{
                                labels: this.state.chartLabels,
                                datasets: [
                                    {
                                      label: 'Checklistor',
                                      fill: true,
                                      lineTension: 0.5,
                                      backgroundColor: 'rgba(75,192,192,1)',
                                      borderColor: 'transparent',
                                      borderWidth: 2,
                                      data: this.state.chartDatasets
                                    }
                                  ]
                            }}
                            width={250}
                            height={125}
                            options={{ maintainAspectRatio: true }}
                        />
                    </div>
                    <div>
                        <h3>Karta</h3>
                        <ReactMapGL
                        width={'auto'}
                        height={500}
                        latitude={this.state.viewport.latitude}
                        longitude={this.state.viewport.longitude}
                        zoom={this.state.viewport.zoom}
                        mapStyle="mapbox://styles/mapbox/light-v9"
                        mapboxApiAccessToken={'pk.eyJ1Ijoibm9yZW56IiwiYSI6ImNqbHhnYjduYjA3dTEzcHBpdnFrbWZ2NmEifQ.aoRKCkEVUVHTIE3QJv1srg'}
                        onViewportChange={(viewport) => {
                            this.setState({viewport})
                            }}
                        >
                            <this.Markers/>
                        </ReactMapGL>
                    </div>
                </div>
            )
        }
        
    }
}