import React from 'react';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { BrowserRouter as Router, Switch, Route} from "react-router-dom";

// import Home from "./components/home.component";
import Report from "./components/report.component";
import Checklists from "./components/checklistor.component";
import News from "./components/news.component";
import Users from "./components/users.component";
import Home from "./components/home.component";

import Nav from "./components/nav.component";

import Amplify, { I18n } from 'aws-amplify';
import awsconfig from './aws-exports';
import { withAuthenticator, Greetings, SignIn, ConfirmSignIn, RequireNewPassword, VerifyContact, ForgotPassword, TOTPSetup  } from 'aws-amplify-react'; // or 'aws-amplify-react-native';

Amplify.configure(awsconfig);
I18n.setLanguage('sv');

const dict = {
  'sv': {
      'Sign In': "Logga in",
      'Sign in to your account': "Logga in med ditt konto",
      'Username': "Epost",
      'Password': "Lösenord",
      'Enter your username': "Fyll i din epost",
      'Enter your password': "Fyll i ditt lösenord",
      'Hello': "Hej",
      'Sign Out': "Logga ut",
      'Forget your password? ': "Glömt ditt lösenord? ",
      'Reset password': "Återställ lösenord",
      'Reset your password': "Återställ ditt lösenord",
      'Back to Sign In': "Tillbaka till inloggning",
      'Send Code': "Skicka kod",
      'Code': "Kod",
      'New Password': "Nytt lösenord",
      'Resend Code': "Skicka om kod",
      'Submit': "Spara",
      'Invalid verification code provided, please try again.': 'Fel kod. Vänligen prova igen.',
      'Incorrect username or password': 'Felaktig epost eller lösenord',
      'User does not exist': 'Användaren existerar inte',
  }
};

I18n.putVocabularies(dict);

function App() {
  return (
    <Router>
      <div style={{width: '100%', marginBottom: 100}}>
        <div style={{margin: 'auto', width: 900}}>
          <Nav />
          <div style={{backgroundColor: 'white', padding: 20, borderRadius: 20, transition: 'all .3s', boxShadow: '0px 40px 40px -25px rgba(0, 0, 0, .75)'}}>
            <Switch>
              <Route exact path='/' component={Home} />
              <Route exact path='/franvarorapport' component={Report} />
              <Route exact path='/checklistor' component={Checklists} />
              <Route exact path='/nyheter' component={News} />
              <Route exact path='/anvandare' component={Users} />
            </Switch>
          </div>
        </div>
      </div>
    </Router>
  );
}

// const header = Object.assign({}, AmplifyTheme.sectionHeader, {backgroundColor: 'red'})

// const MyTheme = Object.assign({}, AmplifyTheme, { sectionHeader: header });

export default withAuthenticator(App, {
  // Render a sign out button once logged in
  includeGreetings: true, 
  // Show only certain components
  authenticatorComponents: [<Greetings/>, <SignIn/>, <RequireNewPassword/>, <ForgotPassword/>, <TOTPSetup/>, <ConfirmSignIn/>, <VerifyContact/>],
  // customize the UI/styling
  // theme: MyTheme
});