import React, { Component } from "react";

import { Navbar, Nav } from 'react-bootstrap';

import moment from 'moment';
import 'moment/locale/sv';

import logo from '../assets/icon_500-x-500.png'

moment.locale('sv');

export default class Navigation extends Component {

    constructor(props) {
        super(props);
        this.state = {
            
        }
    }

    render() {
        return (
            <Navbar className="navbar">
                <Navbar.Brand href="#home">
                    <img
                        src={logo}
                        width="100"
                        height="100"
                        style={{left: 0}}
                        alt="React Bootstrap logo"
                    />
                </Navbar.Brand>
                <Nav className="mr-auto">
                    <Nav.Link href="/">Start</Nav.Link>
                    <Nav.Link href="/checklistor">Checklistor</Nav.Link>
                    <Nav.Link href="/franvarorapport">Frånvarorapport</Nav.Link>
                    <Nav.Link href="/nyheter">Nyheter</Nav.Link>
                    <Nav.Link href="/anvandare">Användare</Nav.Link>
                </Nav>
            </Navbar>
        )
        
    }
}