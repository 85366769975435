import React, { Component } from "react";

import moment from 'moment';
import 'moment/locale/sv';

import { API, graphqlOperation, Auth } from 'aws-amplify';
import { ListGroup, Spinner, Table, Badge, Form, InputGroup, FormControl, Button } from 'react-bootstrap';
 
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from  "react-datepicker";
import sv from 'date-fns/locale/sv';
registerLocale('sv', sv);
moment.locale('sv');

export default class Users extends Component {

    constructor(props) {
        super(props);
        this.state = {
            users: null,
            loading: true,
            searchText: '',
            searchResults: null,
        }
    }

    componentDidMount() {
        this.getUsers();
    }

    getUsers = async () => {
        let apiName = 'AdminQueries';
        let path = '/listUsers';
        let myInit = { 
            queryStringParameters: {
                
            },
            headers: {
                'Content-Type' : 'application/json',
                Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
            }
        }
        const rest =  await API.get(apiName, path, myInit);
        console.log(rest)
        this.setState({users: rest, loading: false})
    }

    searchTextHandler(event){
        this.setState({searchText: event.target.value})
        if (event.target.value.length > 0) {
            this.getSearchResults();
        }
        else {
            this.setState({searchResults: null})
        }
    }

    searchUser = (user) => {
        if (user.Attributes[2].Value.includes(this.state.searchText)) {
            return user;
        }
    }

    getSearchResults = (event) => {
        var results = this.state.users.Users.filter(this.searchUser);
        this.setState({searchResults: results})
    }

    render() {
        if (this.state.loading) {
            return (
                <div>
                    <Spinner/>
                </div>
            )
        }
        else {
            return (
                <div>
                        <h3>Användare</h3>
                        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 10}}>
                            <InputGroup style={{flex: 3, marginRight: 10}}>
                                <InputGroup.Prepend>
                                    <InputGroup.Text id="inputGroup-sizing-default">Sök</InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control type="text" placeholder="Namn" value={this.state.searchText} onChange={this.searchTextHandler.bind(this)} />
                            </InputGroup>
                            <Button disabled onClick={() => console.log('Lägg till användare')} variant="success">Lägg till användare</Button>
                        </div>
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th>Namn</th>
                                    <th>Epost</th>
                                    <th>Status</th>
                                    <th>Aktiverad</th>
                                </tr>
                            </thead>
                            <tbody>
                        {   this.state.searchResults == null &&
                            this.state.users.Users.map((user, i) => (
                                <tr key={i}>
                                    <td>
                                        {user.Attributes[2].Value}
                                    </td>
                                    
                                { user.Attributes[5] !== undefined ?
                                    <td>
                                        {user.Attributes[5].Value}
                                    </td>
                                    :
                                    <td>
                                    </td>
                                }
                                    <td>
                                        {user.UserStatus}
                                    </td>
                                    <td>
                                        {user.Enabled ? <Badge variant={'success'}><h5>Ja</h5></Badge>:<Badge variant={'danger'}><h5>Nej</h5></Badge>}
                                    </td>
                                </tr>
                            
                            ))
                        }
                        {
                            this.state.searchResults !== null &&
                             this.state.searchResults.map((user, i) => (
                                <tr key={i}>
                                    <td>
                                        {user.Attributes[2].Value}
                                    </td>
                                { user.Attributes[5] !== undefined ?
                                    <td>
                                        {user.Attributes[5].Value}
                                    </td>
                                    :
                                    <td>
                                    </td>
                                }
                                    <td>
                                        {user.UserStatus}
                                    </td>
                                    <td>
                                        {user.Enabled ? <Badge variant={'success'}><h5>Ja</h5></Badge>:<Badge variant={'danger'}><h5>Nej</h5></Badge>}
                                    </td>
                                </tr>
                            ))
                        }
                            </tbody>
                        
                        </Table>
                </div>
            )
        }
        
    }
}