import React, { Component } from "react";
import { API, graphqlOperation, Storage } from 'aws-amplify';

import { Dropdown, Spinner, Form, Table, Modal, Carousel, Accordion, Card, Button } from 'react-bootstrap';

import { Page, Text, View, Document, StyleSheet, Image} from '@react-pdf/renderer';
import { pdf } from '@react-pdf/renderer';

import moment from 'moment';
import 'moment/locale/sv';

import DatePicker from "react-datepicker";
 
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from  "react-datepicker";
import sv from 'date-fns/locale/sv';
import { S3Image } from "aws-amplify-react";
registerLocale('sv', sv);
moment.locale('sv');

const filters = {
    orderNumber: 'Ordernummer',
    orderReference: 'Orderreferens',
    customerCompany: 'Företag',
    customerName: 'Kundnamn',
    date: 'Datum',
    installer: 'Installatör'
}

export default class Checklistor extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            results: [],
            searchType: 'orderNumber',
            searchText: '',
            searchFromdate: '',
            searchTodate: '',
            modalData: null,
            modalVisible: false,
            imagesURLs: [],
        }
    }

    searchChecklist = (query) => {
        API.graphql(graphqlOperation(`query searchChecklist($filter: SearchableChecklistFilterInput!, $limit: Int) {
            searchChecklists(filter: $filter, limit: $limit) {
              items {
                id
                orderNumber
                orderReference
                customerName
                customerCompany
                customerPresent
                customerSignature {
                  key
                }
                customerComment
                customerApproved
                customerGrade
                createdAt
                updatedAt
                updatedBy {
                  id
                }
                images {
                  key
                }
                installer {
                  name
                }
                checklistCompany {
                  name
                }
                installerApproved
                installerComment
                location {
                  lat
                  lon
                }
              }
            }
          }`, { filter: { [this.state.searchType]: { match: query }}, limit: 9999})).then(res => {
              this.setState({results: res.data.searchChecklists.items})
        }).catch(err => console.log(err))
    }

    searchChecklistByInstaller = (query) => {
        API.graphql(graphqlOperation(`query searchEmployeeChecklist($filter: SearchableEmployeeFilterInput!) {
            searchEmployees(filter: $filter, limit: 999) {
              items {
                id
                name
                checklists(limit: 999) {
                  items {
                    id
                    orderNumber
                    orderReference
                    customerName
                    customerCompany
                    customerPresent
                    customerSignature {
                      key
                    }
                    customerComment
                    customerApproved
                    customerGrade
                    createdAt
                    updatedAt
                    updatedBy {
                      id
                    }
                    installer {
                        name
                    }
                    images {
                      key
                    }
                    checklistCompany {
                      name
                    }
                    installerApproved
                    installerComment
                    location {
                      lat
                      lon
                    }
                  }
                }
              }
            }
          }`, { filter: { name: { matchPhrasePrefix: query }}})).then(res => {
              let items = res.data.searchEmployees.items;
              let checklists = [];
              for (let i = 0; i < items.length; i++) {
                let userChecklists = items[i].checklists.items.map((checklist) => {
                    return(checklist)
                });
                checklists.push(userChecklists)
              }
              let flattenData = [].concat(...checklists)
              console.log(flattenData)
              flattenData.sort((a, b) => {
                    if (a.createdAt < b.createdAt) {
                      return 1
                    }
                    if (a.createdAt > b.createdAt) {
                        return -1
                    }
                    return 0;
                })
              this.setState({results: flattenData})
        }).catch(err => console.log(err))
    }

    searchChecklistByDate = (fromDate, toDate) => {
        API.graphql(graphqlOperation(`query searchChecklist($filter: SearchableChecklistFilterInput!) {
            searchChecklists(filter: $filter, limit 999) {
              items {
                id
                orderNumber
                orderReference
                customerName
                customerCompany
                customerPresent
                customerSignature {
                  key
                }
                customerComment
                customerApproved
                customerGrade
                createdAt
                updatedAt
                updatedBy {
                  id
                }
                images {
                  key
                }
                installer {
                  name
                }
                checklistCompany {
                  name
                }
                installerApproved
                installerComment
                location {
                  lat
                  lon
                }
              }
            }
          }`, {filter: {createdAt: {gt: moment(fromDate).format("YYYY-MM-DD[T00:01]")}, and: {createdAt: {lt: moment(toDate).format("YYYY-MM-DD[T23:59]")}}}})).then(res => {
              this.setState({results: res.data.searchChecklists.items})
        }).catch(err => console.log(err))
    }

    searchTextHandler(event){
        console.log(this.state.searchType)
        this.setState({searchText: event.target.value})
        if (event.target.value.length > 2) {
            if (this.state.searchType === 'installer') {
                this.searchChecklistByInstaller(event.target.value);
            }
            else {
                this.searchChecklist(event.target.value);
            }
        }
        else {
            this.setState({results: []})
        }
    }

    searchDateHandler(datatype, data) {
        if (datatype === 'fromdate') {
            this.setState({searchFromdate: data}, () => {
                if ((this.state.searchFromdate !== '') && (this.state.searchTodate !== '')) {
                    this.searchChecklistByDate(this.state.searchFromdate, this.state.searchTodate)
                }
                else {
                    this.setState({results: []})
                }
            })
        }
        if (datatype === 'todate') {
            this.setState({searchTodate: data}, () => {
                if ((this.state.searchFromdate !== '') && (this.state.searchTodate !== '')) {
                    console.log('All OK, search')
                    this.searchChecklistByDate(this.state.searchFromdate, this.state.searchTodate)
                }
                else {
                    this.setState({results: []})
                }
            })
            
        }
    }

    searchTypeHandler(event){
        this.setState({searchType: event, searchText: ''})
    }

    deleteChecklist = (id) => {
        console.log(id)
        const confirmDelete = window.confirm(`Vill du verkligen ta bort checklistan?`);
        if (confirmDelete) {
            API.graphql(graphqlOperation(`mutation deleteChecklist($input: DeleteChecklistInput!) {
                deleteChecklist(input: $input) {
                    id
                }
            }`, {input: {id: id}})).then(() => {
                window.location.reload();
            }).catch(err => console.log(err))
        }
    }

    changeOrdernumber = () => {
        const newOrderNumber = prompt('Fyll i nytt ordernummer');
        console.log(newOrderNumber);
        if (newOrderNumber !== null) {
            const oldOrdernumer = this.state.modalData.orderNumber;
            API.graphql(graphqlOperation(`mutation updateChecklist($input: UpdateChecklistInput!) {
                updateChecklist(input: $input) {
                    id
                }
            }`, {input: {id: this.state.modalData.id, orderNumber: newOrderNumber}})).then(success => {
                console.log(success);
                window.alert(`Ordernumret ändrat från ${oldOrdernumer} till ${newOrderNumber}.\nSidan kommer nu att laddas om.`);
                window.location.reload();
            })
        }

    }

    renderTableData() {
        return this.state.results.map((checklist, index) => {
            return (
                <tr onClick={() => this.setState({modalData: checklist, modalVisible: true})} key={checklist.id}>
                    <td>{checklist.orderNumber}</td>
                    <td>{checklist.orderReference}</td>
                    <td>{checklist.installer.name}</td>
                    <td>{checklist.customerCompany}</td>
                    <td>{moment(checklist.createdAt).format("YYYY-MM-DD HH:mm")}</td>
                </tr>
            )
        })
    }

    ChecklistModal = () => {
        if (this.state.modalVisible) {
            return (
                <Modal
                    size="lg"
                    show={this.state.modalVisible}
                    onHide={() => this.setState({modalVisible: false})}
                    aria-labelledby="example-modal-sizes-title-lg"
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="example-modal-sizes-title-lg">
                            {this.state.modalData.orderNumber}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div id={'root'}>
                            <Accordion defaultActiveKey="0">
                                <Card>
                                    <Card.Header>
                                        <Accordion.Toggle as={Button} variant="link" eventKey="0">
                                            Orderinformation
                                        </Accordion.Toggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="0">
                                        <Card.Body>
                                            <div style={styles.dataContainer}>
                                                <span style={styles.dataTitle}>
                                                    Skapades
                                                </span>
                                                <span style={styles.dataValue}>
                                                    {moment(this.state.modalData.createdAt).format('LLLL')}
                                                </span>
                                            </div>
                                            <div style={styles.dataContainer}>
                                                <span style={styles.dataTitle}>
                                                    Ordernummer
                                                </span>
                                                <span style={styles.dataValue}>
                                                    {this.state.modalData.orderNumber}
                                                </span>
                                            </div>
                                            <div style={styles.dataContainer}>
                                                <span style={styles.dataTitle}>
                                                    Orderreferens
                                                </span>
                                                <span style={styles.dataValue}>
                                                    {this.state.modalData.orderReference}
                                                </span>
                                            </div>
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            {
                                this.state.modalData.images !== null &&
                                <Card>
                                    <Card.Header>
                                        <Accordion.Toggle as={Button} variant="link" eventKey="1">
                                            Bilder
                                        </Accordion.Toggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="1">
                                        <Card.Body>
                                            <Carousel>
                                                {
                                                    this.state.modalData.images.map((image, i) => (
                                                        <Carousel.Item key={i}>
                                                            <S3Image theme={{ photoImg: { width: '100%', height: undefined, objectFit: 'cover', borderRadius: 10 }}} imgKey={image.key} />
                                                        </Carousel.Item>
                                                    ))
                                                }
                                            </Carousel>
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            }
                                <Card>
                                    <Card.Header>
                                        <Accordion.Toggle as={Button} variant="link" eventKey="2">
                                            Teknikerinformation
                                        </Accordion.Toggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="2">
                                        <Card.Body>
                                            <div style={styles.dataContainer}>
                                                <span style={styles.dataTitle}>
                                                    Tekniker
                                                </span>
                                                <span style={styles.dataValue}>
                                                    {this.state.modalData.installer.name}, {this.state.modalData.checklistCompany.name}
                                                </span>
                                            </div>
                                            <div style={styles.dataContainer}>
                                                <span style={styles.dataTitle}>
                                                    Installation färdig
                                                </span>
                                                <span style={styles.dataValue}>
                                                    {this.state.modalData.installerApproved ? 'Ja' : 'Nej'}
                                                </span>
                                            </div>
                                            <div style={styles.dataContainer}>
                                                <span style={styles.dataTitle}>
                                                    Kommentar
                                                </span>
                                                <span style={styles.dataValue}>
                                                    {this.state.modalData.installerComment}
                                                </span>
                                            </div>
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                                <Card>
                                    <Card.Header>
                                        <Accordion.Toggle as={Button} variant="link" eventKey="3">
                                            Kundinformation
                                        </Accordion.Toggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="3">
                                        <Card.Body>
                                            <div style={styles.dataContainer}>
                                                <span style={styles.dataTitle}>
                                                    Kund på plats
                                                </span>
                                                <span style={styles.dataValue}>
                                                    {this.state.modalData.customerPresent ? 'Ja' : 'Nej'}
                                                </span>
                                            </div>
                                            <div style={styles.dataContainer}>
                                                <span style={styles.dataTitle}>
                                                    Företag
                                                </span>
                                                <span style={styles.dataValue}>
                                                    {this.state.modalData.customerCompany}
                                                </span>
                                            </div>
                                            <div style={styles.dataContainer}>
                                                <span style={styles.dataTitle}>
                                                    Kundens namn
                                                </span>
                                                <span style={styles.dataValue}>
                                                    {this.state.modalData.customerName}
                                                </span>
                                            </div>
                                            <div style={styles.dataContainer}>
                                                <span style={styles.dataTitle}>
                                                    Installation godkänd
                                                </span>
                                                <span style={styles.dataValue}>
                                                    {this.state.modalData.customerApproved ? 'Ja' : 'Nej'}
                                                </span>
                                            </div>
                                            <div style={styles.dataContainer}>
                                                <span style={styles.dataTitle}>
                                                    Betyg
                                                </span>
                                                <span style={styles.dataValue}>
                                                    {this.state.modalData.customerGrade}
                                                </span>
                                            </div>
                                            <div style={styles.dataContainer}>
                                                <span style={styles.dataTitle}>
                                                    Kundens kommentar
                                                </span>
                                                <span style={styles.dataValue}>
                                                    {this.state.modalData.customerComment}
                                                </span>
                                            </div>
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            {
                                this.state.modalData.customerSignature !== null &&
                                <Card>
                                    <Card.Header>
                                        <Accordion.Toggle as={Button} variant="link" eventKey="4">
                                            Signatur
                                        </Accordion.Toggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="4">
                                        <Card.Body>
                                        
                                            <div style={{backgroundColor: '#fcfcfc', width: 'auto', display: 'flex', justifyContent: 'center'}}>
                                                <img alt={'signatur'} style={{height: 300, transform: 'rotate(90deg)'}} src={this.state.modalData.customerSignature.key}/>
                                            </div>
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            }
                            </Accordion>
                            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                <Button style={{marginTop: 10}} onClick={() => this.savePdf(<this.PDF/>, `${this.state.modalData.id}.pdf`)} >Exportera PDF</Button>
                                <Button style={{marginTop: 10}} onClick={() => this.changeOrdernumber()} className="btn btn-warning">Ändra ordernummer</Button>
                                {/* <Button style={{marginTop: 10}} disabled onClick={() => this.deleteChecklist(this.state.modalData.id)} className="btn btn-danger">Ta bort</Button> */}
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            )
        }
        else {
            return (
                <>
                </>
            )
        }
    }

    PDF = () => {
        return(
                <Document>
                    <Page size="A4" style={PDFstyles.page}>
                        <View style={{flexDirection: 'row', justifyContent: 'space-between', alignContent: 'center', alignItems: 'center'}}>
                            <Image style={{width:133, height: 60}} source={'AVS-Logo-no-slogan-landscape-200x90.jpg'}/>
                            <Text>Checklista för order {this.state.modalData.orderNumber}</Text>
                        </View>
                        <View style={PDFstyles.section}>
                            <Text style={PDFstyles.subtitle}>Orderinformation</Text>
                            <View style={PDFstyles.row}>
                                <Text style={styles.dataTitle}>Datum & tid för checklista</Text>
                                <Text style={styles.dataValue}>{moment(this.state.modalData.createdAt).format('LLLL')}</Text>
                            </View>
                            <View style={PDFstyles.row}>
                                <Text style={styles.dataTitle}>Orderreferens</Text>
                                <Text style={styles.dataValue}>{this.state.modalData.orderReference}</Text>
                            </View>
                        </View>
                        <View style={PDFstyles.section}>
                            <Text style={PDFstyles.subtitle}>Teknikerinformation</Text>
                            <View style={PDFstyles.row}>
                                <Text style={styles.dataTitle}>Tekniker</Text>
                                <Text style={styles.dataValue}>{this.state.modalData.installer.name}</Text>
                            </View>
                            <View style={PDFstyles.row}>
                                <Text style={styles.dataTitle}>Installation färdig</Text>
                                <Text style={styles.dataValue}>{this.state.modalData.installerApproved ? 'Ja' : 'Nej'}</Text>
                            </View>
                            <View style={PDFstyles.row}>
                                <Text style={styles.dataTitle}>Kommentar</Text>
                                <Text style={styles.dataValue}>{this.state.modalData.installerComment}</Text>
                            </View>
                        </View>
                        <View style={PDFstyles.section}>
                            <Text style={PDFstyles.subtitle}>Kundinformation</Text>
                            <View style={PDFstyles.row}>
                                <Text style={styles.dataTitle}>Kund på plats</Text>
                                <Text style={styles.dataValue}>{this.state.modalData.customerPresent ? 'Ja' : 'Nej'}</Text>
                            </View>
                            <View style={PDFstyles.row}>
                                <Text style={styles.dataTitle}>Företag</Text>
                                <Text style={styles.dataValue}>{this.state.modalData.customerCompany}</Text>
                            </View>
                        {
                            this.state.modalData.customerPresent &&
                            <View>
                                <View style={PDFstyles.row}>
                                    <Text style={styles.dataTitle}>Kundens namn</Text>
                                    <Text style={styles.dataValue}>{this.state.modalData.customerName}</Text>
                                </View>
                                <View style={PDFstyles.row}>
                                    <Text style={styles.dataTitle}>Installation godkänd</Text>
                                    <Text style={styles.dataValue}>{this.state.modalData.customerApproved ? 'Ja' : 'Nej'}</Text>
                                </View>
                                <View style={PDFstyles.row}>
                                    <Text style={styles.dataTitle}>Installationsbetyg</Text>
                                    <Text style={styles.dataValue}>{this.state.modalData.customerGrade}</Text>
                                </View>
                                <View style={PDFstyles.row}>
                                    <Text style={styles.dataTitle}>Kommentar</Text>
                                    <Text style={styles.dataValue}>{this.state.modalData.customerComment}</Text>
                                </View>
                            {
                                this.state.modalData.customerSignature !== null &&
                                <View>
                                    <Image style={{height: 'auto', width: 125, marginLeft: 140, marginTop: -40, transform: 'rotate(90deg)'}} src={this.state.modalData.customerSignature.key}/>
                                    <Text style={{marginTop: -90}}>Signatur: _________________________</Text>
                                    <Text style={{marginTop: 10}}>Namnförtydligande: {this.state.modalData.customerName}</Text>
                                </View>
                            }
                            </View>
                        }
                        </View>
                    </Page>
                        
                     
            {
                this.state.imagesURLs.length > 0 &&
                this.state.imagesURLs.map((url, i) => (
                    <Page wrap={false} size="A4" key={i} style={PDFstyles.page}>
                        <View style={{height: 50}}>
                            <Text>Bild {i+1} av {this.state.imagesURLs.length}</Text>
                        </View>
                        <View style={{height: '90%'}}>
                            <Image style={{objectFit: 'contain'}} src={{uri: url, method: 'GET'}}/>
                        </View>
                    </Page>
                ))
            }
                </Document>
        )
    }

    saveBlob = (blob, filename) => {
        var a = document.createElement("a");
        document.body.appendChild(a);
        a.style.display = "none";
        let url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = filename;
        a.click();
        window.URL.revokeObjectURL(url);
    };

    savePdf = async (document, filename) => {

        this.keysToURLs().then((res) => {
            this.setState({imagesURLs: res})
            console.log(res)
            console.log('Generate URLS done')
        }).then(async() => {
            console.log('Save PDF')
            this.saveBlob(await pdf(document).toBlob(), filename)
        })
    };

    keysToURLs = async () => {
        const data = Promise.all(
            this.state.modalData.images.map(async (image) => await Storage.get(image.key))
        )
        return data;
    }

    render() {

        if (this.state.loading) {
            return(
                <div>
                    <Spinner animation="border" role="status">
                        <span className="sr-only">Loading...</span>
                    </Spinner>
                </div>
            )
        }
        
        return (

            <div>
                <this.ChecklistModal/>
                <form>
                    <h3>Sök checklista</h3>
                    <div className="form-row">
                        <div className="form-group col-md-2">
                            <label>Sök efter</label>
                            <Dropdown>
                                <Dropdown.Toggle onSelect={this.searchTypeHandler.bind(this)} variant="success" id="dropdown-basic">
                                    {filters[this.state.searchType]}
                                </Dropdown.Toggle>

                                <Dropdown.Menu onSelect={(value) => console.log(value)}>
                                    <Dropdown.Item onSelect={this.searchTypeHandler.bind(this)} eventKey="orderNumber">Ordernummer</Dropdown.Item>
                                    <Dropdown.Item onSelect={this.searchTypeHandler.bind(this)} eventKey="orderReference">Orderreferens</Dropdown.Item>
                                    <Dropdown.Item onSelect={this.searchTypeHandler.bind(this)} eventKey="customerCompany">Företag</Dropdown.Item>
                                    <Dropdown.Item onSelect={this.searchTypeHandler.bind(this)} eventKey="customerName">Kundnamn</Dropdown.Item>
                                    <Dropdown.Item onSelect={this.searchTypeHandler.bind(this)} eventKey="date">Datum</Dropdown.Item>
                                    <Dropdown.Item onSelect={this.searchTypeHandler.bind(this)} eventKey="installer">Installatör</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    { this.state.searchType !== 'date' ?
                        <div className="form-group col-md-10">
                            <label>Sökdata</label>
                            <Form.Control type="text" placeholder="I12345, Rum 23, Malmö Stad, Per Persson etc." value={this.state.searchText} onChange={this.searchTextHandler.bind(this)} />
                        </div>
                        : 
                        <div style={{display: 'flex', flexDirection: 'row'}}>
                            <div style={{width: '45%'}}>
                            <label>Från</label>
                                <DatePicker
                                    className="form-control"
                                    selected={this.state.searchFromdate}
                                    onChange={(date) => this.searchDateHandler('fromdate', date)}
                                    locale="sv"
                                    dateFormat="yyyy-MM-dd"
                                />
                            </div>
                            <div style={{width: '45%'}}>
                                <label>Till</label>
                                <DatePicker
                                    className="form-control"
                                    selected={this.state.searchTodate}
                                    onChange={(date) => this.searchDateHandler('todate', date)}
                                    locale="sv"
                                    dateFormat="yyyy-MM-dd"
                                />
                            </div>
                        </div>
                    }
                    </div>
                </form>
                <div>
                    <Table striped bordered hover>
                        <tbody>
                            <tr>
                                <th>Ordernummer</th>
                                <th>Orderreferens</th>
                                <th>Installatör</th>
                                <th>Företag</th>
                                <th>Datum</th>
                            </tr>
                            {this.renderTableData()}
                        </tbody>
                    </Table>
                </div>
            </div>
        )
        
    }
}

const styles = {
    dataContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    dataTitle: {
        fontSize: 16,
        fontWeight: '600'
    },
    dataValue: {
        fontSize: 16,
        fontWeight: '300'
    },
    subtitle: {
        textAlign: 'center',
        fontSize: 20,
        fontWeight: '900',
        marginBottom: 25
    }
}

const PDFstyles = StyleSheet.create({
    page: {
        padding: 20,
    },
    row: {
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    section: {
      marginTop: 10,
      padding: 25,
      backgroundColor: '#fcfcfc'
    },
    subtitle: {
        textAlign: 'center',
        fontSize: 20,
        fontWeight: '900',
        marginBottom: 10
    }
  });